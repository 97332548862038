import { createApp } from 'vue'
import App from './App.vue'
import './assets/css/Styleguide.css'
import { createRouter, createWebHistory } from 'vue-router'

import Home from './views/Home.vue'
import Portfolio from './views/Portfolio.vue'
import Blog from './views/Blog.vue'
import BlogPost from './views/BlogPost.vue'
import Rental from './views/Rental.vue'
import About from './views/About.vue'
import Contact from './views/Contact.vue'
import Categorie from './views/Categorie.vue'

const routes = [
    { 
        path: '/',
        component: Home 
    },
    { 
        path: '/portfolio',
        component: Portfolio 
    },
    {
      path: '/blog',
      component: Blog
    },
    {
      path: '/blogpost',
      component: BlogPost
    },
    {
      path: '/rental',
      component: Rental
    },
    {
      path: '/about',
      component: About
    },
    {
      path: '/contact',
      component: Contact
    },
    {
      path: '/categorie',
      component: Categorie
    }
  ]
  
  const router = createRouter({
    history: createWebHistory(),
    routes,
  })
  /* eslint-disable */
  router.beforeEach((to, from) => {
    document.title = 'Simonkr'
  })
  /* eslint-enable */

const app = createApp(App)
app.config.globalProperties.$baseUrl = 'https://simonkr.com'

app.use(router)

app.mount('#app')
